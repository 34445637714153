<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!videoMode"
                class="header"
            >
        <span class="title">
          {{ $t('observations_videos.title') }}
        </span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>
        <keep-alive>
            <search-filter
                v-if="!videoMode"
                :is-admin="isAdmin"
                :user="user"
                @search="fetchObservationsVideosMetadata"
            />
        </keep-alive>
        <keep-alive>
            <observation-video-list
                v-if="!videoMode"
                :can-play="canPlay"
                :videos-metadata="videosMetadata"
                @videoMetadata="initRoad"
                @showVideoView="showVideoView"
            />
        </keep-alive>
        <div class="video-container">
            <observation-video
                v-if="videoMode"
                ref="observationVideo"
                :rear-videos="rearVideos"
                :front-videos="frontVideos"
                @clearRouteProgress="clearMapRouteProgress"
                @routeProgress="setMapRouteProgress"
            />
        </div>

        <div
            v-if="videoMode"
            style="height:50vh !important;"
        >
            <map-container
                ref="mapContainer"
                :find-user="false"
                @onMapClicked="setVideoOffset"
                @onPolylineTap="onPolylineTap"
                @onMarkerTap="onMarkerTap"
                @onMarkerPointerEnter="onMarkerPointerEnter"
                @onMarkerPointerLeave="onMarkerPointerLeave"
            />
            <observation-map
                v-if="map"
                ref="observations"
                :is-admin="isAdmin"
                :is-observer="isObserver"
                :user="user"
                :map="map"
                :observation-results="observations"
            />
        </div>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import ObservationVideo from './ObservationVideo'
import {restApi} from '../../mixins/RestApiMixin'
import ObservationVideoList from './ObservationVideoList'
import SearchFilter from '../../search/SearchFilter'
import ObservationMap from '../ObservationMap'
import {mapHelper} from '@/components/mixins/MapMixin';
import MapContainer from "../../map/MapContainer";

export default {
    name: 'ObservationsVideos',
    components: {MapContainer, ObservationMap, SearchFilter, ObservationVideoList, ObservationVideo},
    mixins: [restApi, mapHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            videosMetadata: [],
            rearVideos: [],
            rearVideoRouteMetadata: [],
            frontVideos: [],
            frontVideoRouteMetadata: [],
            canPlay: false,
            videoMode: false,
            loading: false,
            observations: [],
            lastObservationSearchParams: null,
            map: null,
            zoomedToRoute: false
        }
    },
    mounted() {

    },
    methods: {
        fetchObservationsVideosMetadata: function (params) {
            this.loading = true
            this.restFetchParams(this.observationVideoMetadataUrl, params, this.handleResponse)
        },

        fetchObservationVideos: function (metadata, isFrontVideo) {
            this.restFetchVideo(this.observationVideoMetadataUrl + '/' + metadata.id + '/url', {
                metadata,
                isFrontVideo
            }, this.handleVideoResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response.data) {
                this.videosMetadata = this.combineRoutesMetadata(response.data)
            }
        },

        fetchObservationsForVideos: function (recordingIds) {
            this.observations = []
            let params = ''
            recordingIds.forEach(item => {
                if (params.length < 1) {
                    params += '?'
                } else {
                    params += '&'
                }
                params += 'recordings[]=' + item
            })

            this.restFetch(this.observationVideosUrl + params, this.handleObservationResponse)
        },

        handleObservationResponse: function (response) {
            this.loading = false
            if (response.data) {
                this.observations = response.data
            }
        },

        handleVideoResponse: function (response, videoData) {
            if (videoData.isFrontVideo) {
                this.frontVideos.push({data: videoData.metadata, video: response.data})
                if (this.frontVideos.length >= this.frontVideoRouteMetadata.length) {
                    this.loading = false
                }
            } else {
                this.rearVideos.push({data: videoData.metadata, video: response.data})
                if (this.rearVideos.length >= this.rearVideoRouteMetadata.length) {
                    this.loading = false
                }
            }

            if (this.rearVideos.length >= this.rearVideoRouteMetadata.length && this.frontVideos.length >= this.frontVideoRouteMetadata.length) {
                this.canPlay = true
            }
        },

        shouldBreakList: function (itemA, itemB) {
            return (
                this.isVehicleChanged(itemA, itemB) ||
                this.isOrderChanged(itemA, itemB) ||
                this.isTimeExceeded(itemA, itemB)
            )
        },

        combineRoutesMetadata: function (metadata) {
            // Create route playlist
            let lastItem
            let playlistArray = []
            let playlist = []
            metadata.forEach(function (item) {
                if (lastItem !== undefined && this.shouldBreakList(lastItem, item)) {
                    if (playlist && playlist.length > 0) {
                        playlistArray.push(playlist)
                        playlist = []
                    }
                }
                playlist.push(item)
                lastItem = item
            }, this)
            if (playlist && playlist.length > 0) {
                playlistArray.push(playlist)
            }
            return playlistArray
        },

        isVehicleChanged: function (itemA, itemB) {
            return itemA.vehicle.id !== itemB.vehicle.id
        },

        isOrderChanged: function (itemA, itemB) {
            return itemA.work_order.id !== itemB.work_order.id
        },

        isTimeExceeded: function (itemA, itemB) {
            let timeThreshold = 15 * 60 * 1000 // 15 minutes
            return (new Date(itemB.start_time) - new Date(itemA.end_time) > timeThreshold)
        },

        showVideoView: function () {
            this.videoMode = true
            this.zoomedToRoute = false
            // Wait map and video view to mount
            let recordingIds = []
            this.$nextTick(() => {
                this.map = this.$refs.mapContainer.getMap()
                this.frontVideoRouteMetadata.forEach(videoMetadata => {
                    if (videoMetadata.geometry) {
                        this.setRoute(videoMetadata.id, JSON.parse(videoMetadata.geometry), true)
                        recordingIds.push(videoMetadata.id)
                    }
                })
                this.rearVideoRouteMetadata.forEach(videoMetadata => {
                    if (videoMetadata.geometry) {
                        this.setRoute(videoMetadata.id, JSON.parse(videoMetadata.geometry), false)
                        recordingIds.push(videoMetadata.id)
                    }
                })
                this.fetchObservationsForVideos(recordingIds)
            })
        },

        initRoad: function (metadata, isFrontVideo) {
            this.canPlay = false
            if (isFrontVideo) {
                this.frontVideos = []
                this.frontVideoRouteMetadata = metadata
            } else {
                this.rearVideos = []
                this.rearVideoRouteMetadata = metadata
            }
            this.loading = true
            metadata.forEach(videoMetadata => {
                this.fetchObservationVideos(videoMetadata, isFrontVideo)
            })
        },

        setRoute: function (id, geometry, isFrontVideo) {
            if (geometry && geometry.length > 1) {
                let color = isFrontVideo ? '#0000ff' : '#ffa500'
                this.$refs.mapContainer.getMap().drawObservationVideoRoute(color, id, geometry)
                if (!this.zoomedToRoute && geometry.length > 0) {
                    this.$refs.mapContainer.getMap().zoomToPosition(geometry[0].lat, geometry[0].lon)
                }
            }
        },

        setMapRouteProgress: function (pointIndex, routeId, videoEnded) {
            this.$refs.mapContainer.getMap().drawRouteProgressPolyLine(pointIndex, routeId, videoEnded)
        },

        clearMapRouteProgress: function (videos) {
            this.$refs.mapContainer.getMap().removeRouteProgressPolyLines(videos)
        },

        showList: function () {
            if (this.$refs.observations) {
                this.$refs.observations.hideObservations()
            }
            this.map = null
            this.videoMode = false
            this.canPlay = false
        },

        setVideoOffset: function (point) {
            this.$refs.observationVideo.videoTimeOffset(point)
        },

        onPolylineTap: function (data) {
            // Pass to corresponding function
            if (data.type) {
                if (data.type === this.OBSERVATION) {
                    this.$refs.observations.onPolylineTap(data)
                }
            }
        },

        onMarkerTap: function (data) {
            if (data.type) {
                if (data.type === this.OBSERVATION) {
                    this.$refs.observations.onMarkerTap(data)
                }
            }
        },

        onMarkerPointerEnter: function (data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.$refs.observations.showObservationTitle(data)
                    break
            }
        },

        onMarkerPointerLeave: function (data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.$refs.observations.hideObservationTitle(data)
                    break
            }
        },

    }
}
</script>

<style scoped>
.item-map {
    border-radius: .5em;
    border: 5px solid #ffffff;
    max-height: 30em;
}

.video-container {
    height: 40%;
}
</style>
