<template>
    <div
        class="details-container"
    >
        <div
            class="details dynamic_width"
        >
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
            <div
                style="height:70vh !important;"
            >
                <map-container
                    ref="mapContainer"
                    :find-user="false"
                    :menu-items="menuItems"
                    @onPolylineTap="onPolylineTap"
                    @onMapClicked="mapClicked"
                    @onDrag="onDrag"
                    @onDragEnd="onDragEnd"
                    @onMapChanged="mapChanged"
                />
                <div class="map-controls">
                    <div class="map-controls__list col-12 col-lg-4 nopads">
                        <measure-distance-map
                            v-if="measureDistanceEnabled"
                            ref="measureDistances"
                            :map="map"
                            :measure-distance-results="measureDistances"
                            :measure-distance-target-item="measureDistanceTargetItem"
                            :measure-distance-target-item-callback="measureDistanceTargetItemCallback"
                            :draggable="true"
                            @onRemoveMeasureDistances="removeMeasureDistances"
                        />
                        <measure-road-map
                            v-if="measureRoadEnabled"
                            ref="measureRoad"
                            :map="map"
                            :hide-make-new-observation-button="true"
                            :draggable="true"
                            @close="measureRoadEnabled = false"
                            @confirmSelection="createLineObservation"
                        />
                    </div>
                </div>
            </div>
            <div class="map-trip-details-table pl-1 pt-1 pr-1">
                <b-table
                    show-empty
                    small
                    selectable
                    ref="selectableTable"
                    :select-mode="selectMode"
                    selected-variant="success"
                    striped hover
                    :items="tableItems"
                    :fields="fields"
                    @row-selected="showSkiTrackOnMap"
                >
                </b-table>
            </div>
            <b-row class="nopads">
                <div class="col-md-6 nopads">
                    <div class="button-container" style="text-align: left">
                        <b-button
                            class="result-button"
                            style="min-width: 7em"
                            variant="primary"
                            @click="showAllTracks"
                        >
                            {{ $t('common.show_all') }}
                        </b-button>
                    </div>
                </div>
                <div class="col-md-6 nopads">
                    <div class="button-container">
                        <b-button
                            variant="primary"
                            class="result-button"
                            @click.stop="close"
                        >
                            {{ $t('common.close') }}
                        </b-button>
                    </div>
                </div>
            </b-row>
            <map-layer-selector
                v-if="mapLayerSelector"
                @close="mapLayerSelector = false"
                @onMapSelected="onMapSelected"/>
        </div>
    </div>
</template>

<script>
import MapContainer from "../../map/MapContainer";
import {EventBus} from '@/event-bus'
import {mapHelper} from '@/components/mixins/MapMixin';
import {restApi} from "@/components/mixins/RestApiMixin";
import {sportHelper} from '@/components/mixins/SportMixin';
import {timeUtils} from '@/components/mixins/TimeUtils'
import {measureDistanceMixin} from "@/components/mixins/MeasureDistanceMixin";
import MeasureDistanceMap from "@/components/map/MeasureDistanceMap";
import MeasureRoadMap from "@/components/map/MeasureRoadMap";
import MapLayerSelector from "@/components/map/MapLayerSelector";


export default {
    name: "SportWorkDetails",
    components: {MapContainer, MapLayerSelector, MeasureDistanceMap, MeasureRoadMap},
    mixins: [
        restApi,
        sportHelper,
        mapHelper,
        measureDistanceMixin,
        timeUtils,
    ],
    props: {
        sportWorkItem: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            map: null,
            tableItems: [],
            results: [],
            polyLines: [],
            selectedTrack: null,
            visibleBubble: null,
            loading: false,
            mapItem: null,
            selectMode: 'single',
            mapLayerSelector: false,
            measureRoadEnabled: false,
            measureDistances: [],
            measureDistanceEnabled: false,
            measureDistanceTargetItem: null,
            fields: [
                {
                    id: {
                        label: this.$t('sports.sport_work_details.id'),
                        sortable: true
                    }
                },
                {
                    time: {
                        label: this.$t('sports.sport_work_details.time'),
                        sortable: true
                    }
                },
                {
                    routeName: {
                        label: this.$t('sports.sport_work_details.route_name'),
                        sortable: true
                    }
                },
                {
                    county: {
                        label: this.$t('sports.sport_work_details.county'),
                        sortable: true
                    }
                },
            ]
        }
    },
    watch: {
        map() {
            this.fetchSportWorkRecord()
        }
    },
    mounted() {
        this.setMap()
        this.fetchSportWorkRecord()
    },
    beforeDestroy: function () {
        this.hideTrackInfo()
        this.hideTracks()
    },
    computed: {
        menuItems() {
            return [
                !this.measureDistanceEnabled ? {
                    text: this.$t('map.measure_distance'),
                    onClick: this.initMeasureDistance
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureDistances
                },
                !this.measureRoadEnabled ? {
                    text: this.$t('map.measure_road'),
                    onClick: this.initMeasureRoad
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureRoads
                },
                {
                    text: this.$t('map.layers'),
                    onClick: this.showMapLayerSelector
                },
            ]
        }
    },
    methods: {
        setMap() {
            this.$nextTick(() => {
                this.map = this.$refs.mapContainer.getMap()
            })
        },
        onDrag: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
        },

        onDragEnd: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(data)
            }
        },

        mapClicked: function (coord) {
            if (this.measureDistanceEnabled) {
                this.addMeasureDistance(coord)
            }

            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(coord)
            }
        },

        mapChanged(map) {
            this.map = map
        },
        initTableItems: function () {
            this.tableItems = []
            if (this.results && this.results.length > 0) {
                this.results.forEach((item) => {
                    this.tableItems.push({
                        id: item.id,
                        time: this.toLocalTime(this.sportWorkItem.time),
                        routeName: item.routeName,
                        county: item.comName
                    })
                })
            }
        },
        close: function () {
            this.$emit('close')
        },
        fetchSportWorkRecord() {
            this.loading = true
            this.results = []
            this.tableitems = []
            if(this.sportWorkItem) {
                this.restFetch(this.skiingTrackUrl + '/' + this.sportWorkItem.track_id, this.handleResponse, this.handleError)
            }
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
            this.initTableItems()
            this.showTracks(response.data)
            this.map.zoomToPosition(response.data[0].geometry[0]['y'], response.data[0].geometry[0]['x'])
        },

        showTracks: function (tracks) {
            tracks.forEach(item => {
                if (item.geometry && item.geometry.length > 1) {
                    this.drawSkiingTrack(item.id, item.typeName, item.geometry)
                    this.polyLines.push(item.id)
                }
            })
        },
        showAllTracks() {
            this.showTracks(this.results)
            this.$refs.selectableTable.clearSelected()
        },
        hideTracks: function () {
            this.polyLines.forEach(line => {
                this.map.removePolyline(line, this.SKI_TRACK)
            })
        },
        showSkiTrackOnMap(item) {
            if(item && item[0]) {
                let selectedTrack = this.results.find(result => result.id === item[0].id)
                this.hideTracks()
                this.showTracks([selectedTrack])
                this.map.zoomToPosition(selectedTrack.geometry[0]['y'], selectedTrack.geometry[0]['x'])
            }
        },
        drawSkiingTrack: function (id, type, coords) {
            let color = this.getTypeColor(type)
            this.map.drawPolyLine(id, this.SKI_TRACK, coords, color, false, true, 3)
        },
        onPolylineTap: function (data) {
            this.showTrackInfo(data.id)
        },

        showTrackInfo: function (id) {
            this.hideTrackInfo()
            let track = this.results.find(item => item.id === id)
            if (track) {
                let data = [
                    {name: this.$t('sports.route'), value: track.routeName}
                ]
                this.map.showPolylineBubble(track.id, this.SKI_TRACK, track.geometry[0].y, track.geometry[0].x, this.$t('sports.skiing_track'), data)
                this.visibleBubble = id
                this.selectedTrack = track
            }
        },
        hideTrackInfo: function () {
            if (this.visibleBubble) {
                this.map.hidePolylineBubble(this.visibleBubble, this.SKI_TRACK)
                this.selectedTrack = null
            }
        },
        getTypeColor: function (type) {
            switch (parseInt(type)) {
                case this.SKI_FREESTYLE:
                    return '#52f0ff'
                case this.SKI_CLASSIC:
                    return '#5148ff'
                case this.SKI_LIGHTS:
                    return '#ffbd13'
                case this.SKI_COMPETITION:
                    return '#e454ff'
            }
            return '#2d3b38'
        },
        handleError: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
        showMapLayerSelector: function () {
            this.mapLayerSelector = true
        },

        async onMapSelected(layer) {
            this.loading = true
            await this.$refs.mapContainer.setMap(layer);
            await this.$refs.mapContainer.saveMapSettings();
            this.mapLayerSelector = false
            this.loading = false
        },
    }
}
</script>
