<template>
    <div class="col-12 nopads">
        <div class="order-text pl-2" :style="{width: textColWidthPercentage + '%', marginTop: '-2px', marginBottom: '-2px', borderTop: '1px #BCBCBC solid', borderBottom: '1px #BCBCBC solid'}">
            <b-row class="col-12 nopads">
                <div class="col-9 nopads single-line">
                    <font-awesome-icon
                        icon="caret-right"
                        class="pointer ml-1"
                        style="outline: none; width: 2.5em; height: 1.1em"
                        :class="{ 'fa-rotate-90': showInfo }"
                        @click.stop="toggleExtraInfo"/>
                    <span class="ml-2" :class="{'disabled-contract' : disableContractSelection}">
                        {{ $t('contracts.duty_officer_schedule') }}
                    </span>
                </div>
            </b-row>
        </div>
        <b-collapse v-model="showInfo">
            <div v-if="loading > 0" class="loading-container">{{ $t('common.loading') }}</div>
            <div v-else>
                <div
                    v-for="dutyOfficerObject in schedules"
                    v-bind:key="dutyOfficerObject.id"
                    class="col-12 nopads">
                    <!-- Text part -->
                    <div class="order-text sub-item" :style="{width: textColWidthPercentage + '%', color: '#636364', borderTop: '1px #BCBCBC solid'}">
                        <b-row class="nopads pl-2">
                            <div class="col-9 nopads" style="padding-left: 2em;">
                                {{ dutyOfficerObject.duty_officer.first_name }} {{ dutyOfficerObject.duty_officer.last_name }}
                                ({{ toLocalTime(dutyOfficerObject.start_date) }} - {{ toLocalTime(dutyOfficerObject.end_date) }})
                            </div>
                            <div class="col-3 nopads text-right pr-2">
                                        <i :id="'dt_'+dutyOfficerObject.id" class="fa fa-trash"/>
                                        <b-tooltip :target="'dt_'+dutyOfficerObject.id" triggers="hover" placement="right">
                                            {{ $t('common.remove') }}
                                        </b-tooltip>
                            </div>
                        </b-row>
                    </div>
                    <!-- Time line -->
                    <div style="display: inline-block; user-select: none">
                        <div id="timeline-container" style="display: flex;  position: relative">
                            <div class="week-item"
                                 :style="{width: weekColWidth + 'px'}"
                                 v-for="(week, index) in weeks"
                                 v-bind:key="index">{{ '-' }}
                            </div>
                            <!-- Draggable and resizable time line -->
                            <time-line-item
                                :time-col-width="weekColWidth"
                                :timeline-start-date="new Date(calendarStartDate)"
                                :calendar-start-date="new Date(calendarStartDate)"
                                :visible-weeks-limit="visibleWeeksLimit"
                                :calendar-start-week="calendarStartWeek"
                                :startDate="dutyOfficerObject.start_date ? getDateFromString(dutyOfficerObject.start_date) : null"
                                :endDate="dutyOfficerObject.end_date ? getDateFromString(dutyOfficerObject.end_date) : null"
                                :static="true"/>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>


<script>

import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import TimeLineItem from "@/components/contractcalendar/TimeLineItem";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {workAssignmentHelper} from "@/components/mixins/WorkAssignmentMixin";
import {EventBus} from "@/event-bus";

export default {
    name: 'ContractCalendarItem',
    components: {TimeLineItem},
    mixins: [timeUtils, restApi, orderMixin, workAssignmentHelper],
    data: function () {
        return {
            start: null,
            loading: false,
            showInfo: false,
            weeks: [],
            dutyOfficerSchedules: [],
        }
    },
    props: {
        schedules: {
            type: Array,
            default() {
                return []
            }
        },
        textColWidthPercentage: {
            type: Number,
            default: 40
        },
        timeColWidth: {
            type: Number,
            default: 100  // px
        },
        calendarStartDate: {
            type: Date,
            default: null
        },
        visibleWeeksLimit: {
            type: Number,
            default: 20
        },
        calendarStartWeek: {
            type: Number,
            default: 10
        },
        assignmentFilter: {
            type: Array,
            default: null
        },
        autoExpand: {
            type: Boolean,
            default: false
        },
        disableContractSelection: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        weekColWidth() {
            return Math.round(this.timeColWidth / 7) * 7
        },
    },
    watch: {
        calendarStartDate: function () {
            this.initTable()
        },
        calendarStartWeek: function () {
            this.initTable()
        },
    },
    mounted: function () {
        this.initTable();
    },
    methods: {

        deleteSchedule(id) {
            this.loading = true
            this.restDelete(this.dutyOfficerScheduleUrl + `?id=${id}`, () => {
                this.loading = false
                this.$emit('deleteDutyOfficerSchedule', id)
                EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            }, () => {
                this.loading = false
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            })
        },

        toggleExtraInfo() {
            this.showInfo = !this.showInfo;
        },

        sortByDate(arr) {
            arr.sort((a, b) => {
                if (a.planned_start === undefined) {
                    return 1;
                }
                if (b.planned_start === undefined) {
                    return -1;
                }
                return new Date(b.planned_start).getTime() - new Date(a.planned_start).getTime();
            });
        },
        initTable() {
            this.weeks = []
            this.start = this.calendarStartWeek;
            let startWeek = this.start
            for (let i = 0; i < this.visibleWeeksLimit; i++) {
                this.weeks.push(this.$t('calendar.wk_prefix') + (startWeek + i))
            }
        },


    }
}
</script>
<style scoped>
.calendar-item-container {
    user-select: none;
}

.expanded {
    background: #FFFFFF !important;
}

.contract-header-text, .week-item, .order-text {
    display: inline-block;
    background: #ffffff;
    border-top: 1px #BCBCBC solid;
    border-right: 1px #BCBCBC solid;
    margin: 0;
    padding: .1em;
    font-size: .9em;
    user-select: none;
    min-height: 2em;
}

.contract-header-text {
    font-weight: bold;
    background: #f6f5f1;
}

.order-text {
    color: #404041;
    padding-left: 2.2em;
}

.contract-header-text:hover {
    color: #007BFF;
    font-weight: bold;
    cursor: pointer;
}

.disabled-contract {
    color: #404041 !important;
    cursor: default;
}

.week-item {
    color: #FFFFFF;
    user-select: none;
    padding: .1em;
}

.loading-container {
    padding: .5em;
    background: #ffffff;
    margin: 0;
}

.dropdown-adder {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 1.4em;
}

.btn-sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.single-line {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


</style>
