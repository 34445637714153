<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showReportsList"
        >
            <div
                v-if="!detailsMode"
                class="header"
            >
                <span class="title">{{ $t('ins_report.ins_report') }}</span>
            </div>
            <div
                v-else
                class="header col-sm-12 nopads"
                @click="showReportsList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
                <font-awesome-icon
                    icon="print"
                    class="print-icon"
                    @click.stop="generatePdf"
                />

                <b-button
                    v-if="reportDetails.location !== undefined"
                    class="m-2 print-icon"
                    variant="info"
                    size="md"

                    @click.stop="showModal"
                >
                    <span class="map-button-text">{{ $t('menu.map') }}</span>
                </b-button>

            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <inspection-report-list
                        v-if="!detailsMode"
                        :results="results"
                        @search="searchReport"
                        @details="showReportDetails"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <inspection-report-details
                    v-if="detailsMode"
                    id="details"
                    ref="details"
                    :report-details="reportDetails"
                    :user="user"
                    @close="showReportsList"
                />
            </transition>
            <inspection-map
                :center="reportDetails.location"
                id="InspectionMap"
                ref="InspectionMap"
                />
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import InspectionReportList from './InspectionReportList'
import InspectionReportDetails from './InspectionReportDetails'
import inspectionMap from "@/components/inspections/InspectionMap.vue";
import {PdfGeneratorManager} from "@/modules/PdfGeneratorManager";
import {PdfGeneratorService} from "@/modules/PdfGeneratorService";
import {StandardFonts} from "pdf-lib";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {inspectionHelper} from "@/components/mixins/InspectionMixin";
import {vehicleHelper} from "@/components/mixins/VehicleMixin";

export default {
    name: 'InspectionReports',
    components: {InspectionReportList, InspectionReportDetails, inspectionMap},
    mixins: [restApi, timeUtils, inspectionHelper, vehicleHelper],
    data: function () {
        return {
            results: [],
            loading: false,
            detailsMode: false,
            reportDetails: [],
            params: {}
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    methods: {
        fetchInspectionReports: function () {
            this.loading = true
            this.restFetchParams(this.inspectionReportGenericUrl, this.params, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response) {
                this.results = response.data
            }
        },

        searchReport: function (params) {
            this.params = params
            this.fetchInspectionReports()
        },
        showReportDetails: function (details) {
            this.reportDetails = details
            this.detailsMode = true
        },
        showReportsList: function () {
            this.detailsMode = false
        },
        print: function () {
            this.$refs.details.print()
        },
        showModal() {
            this.$refs.InspectionMap.showModal()

        },

        async generatePdf() {
            this.loading = true
            const pdfGeneratorManager = new PdfGeneratorManager()
            const pdfDoc = await pdfGeneratorManager.createStandardRoutaReportPdf()
            await PdfGeneratorService.cacheRoutaFooterLogo()
            const footerLogo = pdfGeneratorManager.embeddedImages.footer
            const pdfService = pdfGeneratorManager.pdfGeneratorService

            const font = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.Helvetica)
            const fontBold = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.HelveticaBold)
            // This is used but for some reason lint does not see it
            // eslint-disable-next-line no-unused-vars
            let nextYPosition = undefined
            // Document title
            let title = this.$t('ins_report_details.title')
            nextYPosition -= 10

            nextYPosition = pdfService.addText(pdfDoc, title, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, ' ', font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)

            nextYPosition = pdfService.addVerticalTable([...this.inspectionReports], pdfDoc, {
                textFont: font,
                headerFont: fontBold,
                textFontSize: 9,
                headerFontSize: 9
            }, nextYPosition, undefined, undefined, footerLogo)

            nextYPosition -= 40

            const reportTitle = this.$t('ins_report_details.reporter_certificates')
            nextYPosition = pdfService.addText(pdfDoc, reportTitle, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, ' ', font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)

            nextYPosition -= 10


            nextYPosition = pdfService.addTable([[this.$t('ins_report_details.certificate'), this.$t('ins_report_details.granted'), this.$t('ins_report_details.expires')], ...this.reportCertificates], pdfDoc, {
                textFont: font,
                headerFont: fontBold,
                textFontSize: 9,
                headerFontSize: 9
            }, nextYPosition, undefined, undefined, footerLogo)

            nextYPosition -= 40


            const inspectionTarget = this.$t('ins_report_details.target')
            nextYPosition = pdfService.addText(pdfDoc, inspectionTarget, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, ' ', font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)

            nextYPosition = pdfService.addVerticalTable([...this.inspectionTarget], pdfDoc, {
                textFont: font,
                headerFont: fontBold,
                textFontSize: 9,
                headerFontSize: 9
            }, nextYPosition, undefined, undefined, footerLogo)


            nextYPosition -= 40

            const resultTitle = this.$t('ins_report_details.ins_results')
            nextYPosition = pdfService.addText(pdfDoc, resultTitle, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, ' ', font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)

            nextYPosition -= 10

            for (const inspection of this.reportResults) {

                nextYPosition -= 20

                const currentPages = pdfDoc.getPages().length


                const available = nextYPosition - pdfService.footerZone - 120

                if (available < 0) {
                    nextYPosition -= 800
                }


                const result = pdfService.addVerticalTable([...this.inspectionResults(inspection)], pdfDoc, {
                    textFont: font,
                    headerFont: fontBold,
                    textFontSize: 9,
                    headerFontSize: 9
                }, nextYPosition, undefined, undefined, footerLogo, 2, 1)


                const newPages = pdfDoc.getPages().length

                //sync components Y position
                if (newPages !== currentPages) {
                    const newPage = pdfDoc.getPage(pdfDoc.getPages().length - 1)
                    nextYPosition = newPage.getHeight() - pdfService.defaultVerticalMargin
                }

                if (inspection.photos && inspection.photos.length > 0) {
                    //Inspection can only have one photo currently
                    const url = this.inspectionResult + inspection.id + '/photo/' + inspection.photos[0].id + '?inspection_type=' + this.getReportType()
                    const photo = (await new Promise(resolve => this.restFetch(url, resolve))).data

                    if (photo) {
                        let image
                        if (this.isPng(photo)) {
                            image = await pdfDoc.embedPng(photo)
                        } else {
                            image = await pdfDoc.embedJpg(photo)
                        }
                        nextYPosition = pdfService.addImage(pdfDoc, undefined, image, undefined, nextYPosition -= 10, footerLogo, 160, 2, 2)
                    }
                } else {
                    nextYPosition = result
                }

            }


            const pdfBytes = await pdfGeneratorManager.generateBytesFromPdfDoc(pdfDoc)
            pdfGeneratorManager.downloadPdf(pdfBytes, this.$t('ins_report_details.title'))
            this.loading = false
        },


        isPng(photo) {
            return photo.startsWith('data:image/png;');
        },

        inspectionResults(inspection) {
            return [
                [this.$t('ins_report_details.inspectable'), inspection.inspectable],
                [this.$t('ins_report_details.result'), this.formatResult(inspection.result)],
                [this.$t('ins_report_details.info'), inspection.info]
            ]

        },

        getReportType: function () {
            if (this.reportDetails.vehicle) {
                return 1
            } else if (this.reportDetails.contract) {
                return 2
            } else if (this.reportDetails.order) {
                return 3
            } else if (this.reportDetails.inspected_company) {
                return 4
            } else if (this.reportDetails.bridge_id) {
                return 5
            } else if (this.reportDetails.culvert_id) {
                return 6
            }
            return null
        },

        formatResult(result) {
            if (result.none != null) {
                return this.getInspectionResultName(result.none)
            } else if (result.number != null) {
                return result.number
            } else if (result.text != null) {
                return result.text
            } else if (result.select_single != null) {
                return result.select_single.name
            } else if (result.select_multi != null) {
                return result.select_multi
            } else if (result.boolean != null) {
                return this.$t(result.boolean ? 'common.yes' : 'common.no')
            }
        },


    },

    computed: {

        reportResults() {
            if (this.reportDetails !== undefined) {
                return this.reportDetails.results.map(({id, inspectable, info, photos, number, text, select_single, select_multi, boolean, result: res}) => {
                    let result;
                    if (number != null) {
                        result = {number};
                    } else if (text != null) {
                        result = {text};
                    } else if (select_single != null) {
                        result = {select_single};
                    } else if (select_multi != null) {
                        result = {select_multi};
                    } else if (boolean != null) {
                        result = {boolean};
                    } else {
                        result = {none: res};
                    }
                    return {id, inspectable, result, info, photos}
                });
            } else {
                return {}
            }
        },

        inspectionReports() {
            return [
                [this.$t('ins_report_details.date'), this.toLocalTime(this.reportDetails.created)],
                [this.$t('ins_report.list_label.report_type'), this.getInspectionTypeName(this.reportDetails.type)],
                [this.$t('ins_report.list_label.name'), this.reportDetails.name],
                [this.$t('inspection_editor.inspection_frequency'), this.getInspectionFrequency(this.reportDetails.frequency)],
                [this.$t('ins_report_details.inspector'), this.getInspector(this.reportDetails.reporter)],
                [this.$t('ins_report.info'), this.reportDetails.info],
                [this.$t('weather_station.location'), this.reportDetails.location === undefined ? this.$t('inspection.unknown_location') : this.reportDetails.location.x + " " + this.reportDetails.location.y]
            ]
        },


        reportCertificates() {
            const results = []
            if (this.reportDetails !== undefined) {
                this.reportDetails.reporter.certificates.forEach(value => {
                    results.push([
                        value.type.name,
                        this.toLocalTime(value.granted_day),
                        this.toLocalTime(value.expiration_day)
                    ])
                })
            }
            return results
        },

        inspectionTarget() {
            if (this.reportDetails.vehicle) {
                return [
                    [this.$t('ins_report_details.owner'), this.reportDetails.vehicle.company.name],
                    [this.$t('vehicle_list.make'), this.reportDetails.vehicle.make],
                    [this.$t('vehicle_editor.model_year'), this.reportDetails.vehicle.model_year],
                    [this.$t('vehicle_list.model'), this.reportDetails.vehicle.vehicle_model],
                    [this.$t('vehicle_list.type'), this.getVehicleTypeName(this.reportDetails.vehicle.vehicle_type)],
                    [this.$t('vehicle_editor.emission_standard'), this.getEmissionStandardName(this.reportDetails.vehicle.emission_standard)],
                    [this.$t('vehicle_editor.emission_standard_other'), this.reportDetails.vehicle.emission_standard_extra_info],
                    [this.$t('ins_report.list_label.license_plate'), this.reportDetails.vehicle.license_plate],
                ]

            } else if (this.reportDetails.contract) {
                return [
                    [this.$t('orders.contract'), this.reportDetails.contract.name],
                    [this.$t('contracts.number'), this.reportDetails.contract.contract_number],
                    [this.$t('contracts.customer'), this.reportDetails.contract.customer.name],
                    [this.$t('contracts.contractor'), this.reportDetails.contract.contractor.name],
                    [this.$t('contracts.start'), this.toLocalTime(this.reportDetails.contract.start_date)],
                    [this.$t('contracts.end'), this.toLocalTime(this.reportDetails.contract.end_date)]
                ]
            } else if (this.reportDetails.order) {
                return [
                    [this.$t('orders.task_type'), this.reportDetails.order.task_type.name],
                    [this.$t('orders.contract'), this.reportDetails.order.contract.name],
                    [this.$t('contracts.number'), this.reportDetails.order.contract.contract_number],
                    [this.$t('contracts.customer'), this.reportDetails.order.contract.customer.name],
                    [this.$t('contracts.contractor'), this.reportDetails.order.contract.contractor.name],
                    [this.$t('contracts.start'), this.reportDetails.order.contract.start_date],
                    [this.$t('contracts.end'), this.reportDetails.order.contract.end_date],
                ]
            } else if (this.reportDetails.inspected_company) {
                return [
                    [this.$t('users.company'), this.reportDetails.inspected_company.name]
                ]
            } else if (this.reportDetails.bridge_id) {
                return [
                    [this.$t('culvert.digiroad_id'), this.reportDetails.bridge_id.digiroad_id],
                    [this.$t('bridge.name'), this.reportDetails.bridge_id.name],
                    [this.$t('bridge.type'), this.reportDetails.bridge_id.type],
                    [this.$t('bridge.bridge_num'), this.reportDetails.bridge_id.bridgeNumber],
                    [this.$t('bridge.city'), this.reportDetails.bridge_id.city],
                    [this.$t('bridge.road_num'), this.reportDetails.bridge_id.roadNum],
                    [this.$t('bridge.sec_num'), this.reportDetails.bridge_id.secNum],
                    [this.$t('bridge.start_distance'), this.reportDetails.bridge_id.startDistanc],
                    [this.$t('bridge.length'), this.reportDetails.bridge_id.length],
                ]
            } else if (this.reportDetails.culvert_id) {
                return [
                    [this.$t('culvert.digiroad_id'), this.reportDetails.culvert_id.digiroad_id],
                    [this.$t('bridge.type'), this.reportDetails.culvert_id.type],
                    [this.$t('culvert.material'), this.culvert_id.material],
                    [this.$t('culvert.size'), this.culvert_id.size],
                    [this.$t('bridge.city'), this.culvert_id.city],
                    [this.$t('bridge.road_num'), this.culvert_id.road_num],
                    [this.$t('bridge.sec_num'), this.culvert_id.sec_num],
                    [this.$t('bridge.start_distance'), this.culvert_id.start_distance],
                    [this.$t('bridge.length'), this.culvert_id.length],
                    [this.$t('culvert.owner'), this.culvert_id.owner],
                ]
            }
            return []
        },
    }

}
</script>
