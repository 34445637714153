<template>
    <div
        class="details-container"
        @click.stop="$emit('close')"
    >
        <div
            class="details"
            style="width: 780px; margin: auto;"
            @click.stop=""
        >
            <div class="col-12 mb-2 pb-2" style="border-bottom: #d9d9d9 1px solid">
                <b-form inline class="justify-content-end">
                    <b-form-checkbox size="sm" switch v-model="showAllPhotos" class="mr-2 ml-2">{{ $t('observations.show_all_photos')}}</b-form-checkbox>
                    <b-button
                        variant="secondary"
                        class="mr-1 ml-1"
                        size="sm"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="primary"
                        class="mr-1 ml-1"
                        size="sm"
                        :disabled="loading > 0"
                        @click.stop="generatePdf"
                    >
                        {{ $t('common.print') }}
                    </b-button>
                </b-form>
            </div>
                <div class="print-page">
                    <b-row class="nopads">
                        <div class="col-6 nopads">
                            <h1 class="report-header">
                                {{ $t('trip.trip_with_observations_pdf_title')}}
                            </h1>
                            {{ getCurrentDay() }}
                        </div>
                        <div class="col-6 nopads" style="text-align: right">
                            <img src="../../assets/routa_dark.jpg"
                                 class="print-logo" alt="logo"/>
                        </div>
                    </b-row>
                    <hr />
                    <!-- Summary map -->
                    <b-row>
                        <div class="map-container summary-map-container">
                            <map-container
                                ref="mapContainer"
                                :find-user="false"
                                @onMapChanged="mapChanged"
                            />
                        </div>
                    </b-row>
                    <!-- Summary figures -->
                    <b-table class="mt-2" small :items="tableItems" :fields="fields"></b-table>
                </div>
                <observation-print-details
                    v-for="observation in observations"
                    ref="observations"
                    :key="observation.id"
                    :observation="observation"
                    :report-completed="reportCompleted"
                    :show-all-photos="showAllPhotos"
                />
            </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        ></div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import MapContainer from "@/components/map/MapContainer";
import {geomTypes} from "@/components/mixins/ObservationMixin";
import {PdfGeneratorService} from "@/modules/PdfGeneratorService";
import {PdfGeneratorManager} from "@/modules/PdfGeneratorManager";
import {StandardFonts} from 'pdf-lib';
import {restApi} from "@/components/mixins/RestApiMixin";
import {observationHelper} from '../mixins/ObservationMixin'
import {mapHelper} from '../mixins/MapMixin'
import ObservationPrintDetails from "@/components/observation/ObservationPrintDetails";

export default {
    name: 'TripWithObservationsReport',
    components: {MapContainer, ObservationPrintDetails},
    mixins: [timeUtils, restApi, observationHelper, mapHelper],
    props: {
        trip: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            map: null,
            observations: [],
            reportCompleted: false,
            showAllPhotos: false,
            loading: 0,
            fields: [
                {key: 'name', label: this.$t('road_reports.type')},
                {key: 'count', label: this.$t('road_reports.count')}
            ],
            items: [],
            tableItems: [],
        }
    },
    mounted() {
        this.map = this.$refs.mapContainer ? this.$refs.mapContainer.getMap() : undefined
        this.initView()
    },
    methods: {
        async generatePdf() {
            this.loading++
            const pdfGeneratorManager = new PdfGeneratorManager()
            const pdfDoc = await pdfGeneratorManager.createStandardRoutaReportPdf()
            await PdfGeneratorService.cacheRoutaFooterLogo()
            const footerLogo = pdfGeneratorManager.embeddedImages.footer
            const pdfService = pdfGeneratorManager.pdfGeneratorService

            const font = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.Helvetica)
            const fontBold = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.HelveticaBold)
            // This is used but for some reason lint does not see it
            // eslint-disable-next-line no-unused-vars
            let nextYPosition = undefined
            // Document title
            let title = this.$t('trip.trip_with_observations_pdf_title')

            nextYPosition = pdfService.addText(pdfDoc, title, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, this.getCurrentDay(), font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)

            const image = await this.getMapScreenshotAsArrayBuffer()
            const mapPhoto = await pdfDoc.embedJpg(image)

            nextYPosition = pdfService.addImage(pdfDoc, undefined, mapPhoto, undefined, nextYPosition, footerLogo)

            nextYPosition -= 10

            const tablePage = pdfDoc.getPage(pdfDoc.getPages().length -1)

            nextYPosition = pdfService.addTable([[this.$t('road_reports.type'), this.$t('road_reports.count')], ...this.items], pdfDoc, {
                textFont: font,
                headerFont: fontBold,
                textFontSize: 9,
                headerFontSize: 9
            }, nextYPosition, undefined, undefined, footerLogo)

            const afterTablePage = pdfDoc.getPage(pdfDoc.getPages().length -1)

            if (afterTablePage === tablePage) {
                nextYPosition -= 500
            }

            nextYPosition -= 10

            let i = 0

            const observations = JSON.parse(JSON.stringify(this.observations)).sort((a, b) => {
                return a.photos.length - b.photos.length
            })

            for (let observation of observations) {

                let yPosition = nextYPosition < 0 ? pdfService.defaultVerticalMargin : nextYPosition
                const lines = Math.round((observation.info ? observation.info.length : 1) / 77)
                //224 is observation length estimate
                const addNewPage = yPosition - (224 + lines) < pdfService.footerZone

                if (observation.photos.length >= 1 || addNewPage) {
                    nextYPosition -= 800
                }

                nextYPosition = pdfService.addHorizontalLine(pdfDoc, nextYPosition)
                nextYPosition -= 10

                nextYPosition = pdfService.addText(pdfDoc, observation.type.name ? observation.type.name : ' ', fontBold, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
                if (observation.info) {
                    nextYPosition = pdfService.addText(pdfDoc, observation.info, font, 10, nextYPosition, undefined, undefined, undefined, footerLogo)
                }

                // Fetch only one if showAllPhotos not enabled
                const photos = await this.fetchPhotos(observation, this.showAllPhotos)

                const images = []

                for (let photo of photos) {
                    let image
                    if (this.isPng(photo)) {
                        image = await pdfDoc.embedPng(photo)
                    } else {
                        image = await pdfDoc.embedJpg(photo)
                    }
                    images.push(image)
                }

                nextYPosition -= 5
                //Observation images
                if (images.length > 1 && this.showAllPhotos) {
                    nextYPosition = pdfService.addImages(pdfDoc, undefined, images, undefined, nextYPosition, footerLogo, 1, 1, 2)
                    nextYPosition -= 55
                } else if (images.length >= 1 || !this.showAllPhotos && images.length >= 1) {
                    nextYPosition = pdfService.addImage(pdfDoc, undefined, images[0], undefined, nextYPosition, footerLogo, 140, 1, 1)
                    nextYPosition -= 55
                }

                const observationMapImage = await this.takePhotoOfMap(i)
                const mapObservationJpg = await pdfDoc.embedJpg(observationMapImage)
                i++

                const currentPages = pdfDoc.getPages().length

                pdfService.addImage(pdfDoc, undefined, mapObservationJpg, undefined, nextYPosition, footerLogo, 102, 2, 1)

                const newPages = pdfDoc.getPages().length

                //sync components Y position
                if (newPages !== currentPages) {
                    const newPage = pdfDoc.getPage(pdfDoc.getPages().length - 1)
                    nextYPosition = newPage.getHeight() - this.defaultVerticalMargin
                }

                nextYPosition = pdfService.addVerticalTable([...this.observationDetails(observation)], pdfDoc, {
                    textFont: font,
                    headerFont: fontBold,
                    textFontSize: 8,
                    headerFontSize: 8
                }, nextYPosition, undefined, undefined, footerLogo, 2, 2, 5)

                nextYPosition -= 20
            }

            const pdfBytes = await pdfGeneratorManager.generateBytesFromPdfDoc(pdfDoc)
            pdfGeneratorManager.downloadPdf(pdfBytes, this.$t('trip.trip_with_observations_pdf_title'))
            this.loading--
        },
        isPng(photo) {
            return photo.startsWith('data:image/png;');
        },
        async takePhotoOfMap(index) {
            const result = this.$refs.observations[index].getMarkerEditorMap()
            const ss = await result.takeScreenShot()
            return await new Response(ss).arrayBuffer();
        },
        async getMapScreenshotAsArrayBuffer() {
            const screenshot = await this.map.takeScreenShot()
            return await new Response(screenshot).arrayBuffer()
        },
        async fetchPhotos(observation, fetchAll = false) {
            const baseUrl = this.observationPhotoUrl
            const id = observation.id
            const results = []
            if(!fetchAll && observation.photos.length > 0) {
                let url = baseUrl + '/' + id + '/' + observation.photos[0].id
                results.push((await new Promise(resolve => this.restFetch(url, resolve))).data)
            } else {
                for (const photo of observation.photos) {
                    let url = baseUrl + '/' + id + '/' + photo.id
                    results.push((await new Promise(resolve => this.restFetch(url, resolve))).data)
                }
            }
            return results
        },
        mapChanged: function (map) {
            this.map = map
            this.initView()
        },
        async initView() {
            if (this.trip && this.map) {
                //Remove any previous observations
                this.map.removeMapItemsByType(this.OBSERVATION)
                this.map.removeMapItemsByType(this.TRACE)

                let observationsData = []
                try {
                    observationsData = await new Promise((resolve, reject) => {
                        this.loading++
                        this.restFetchParams(this.observationUrl, {
                            from: this.trip.start_time,
                            to: this.trip.end_time,
                            reporter: this.trip.driver_id,
                            contract: this.trip.contract_id,
                        }, (response) => {
                            this.loading--
                            resolve(response.data)
                        }, (error) => {
                            this.loading--
                            reject(error)
                        })
                    })

                    this.observations = observationsData
                    // Draw observations
                    this.drawObservations()
                    // Draw the trip
                    this.drawTrip()
                    // Zoom to the trip
                    this.map.zoomToGroup(this.TRACE)
                    // eslint-disable-next-line no-empty
                } catch (error) {}
            }
            // Table summaries
            this.initItems()
        },
        initItems() {
            let table = []
            let pdf = []
            const ids = this.observations.map(ob => ob.type.id)

            const uniqueObservationsByType = this.observations.filter((observation, index, self) =>
                index === self.findIndex((o) => o.type.id === observation.type.id)
            )

            uniqueObservationsByType.forEach(obs => {
                pdf.push([
                        obs.type.name,
                        ids.filter(id => id === obs.type.id).length,
                    ]
                )
                table.push({
                        id: obs.type.id,
                        name: obs.type.name,
                        count: ids.filter(id => id === obs.type.id).length
                    }
                )

            })
            this.items = pdf
            this.tableItems = table
        },
        observationDetails(obs) {
            return [
                [this.$t('observations.id'), obs.id],
                [this.$t('observations.created_time'), this.toLocalTime(obs.created_time)],
                [this.$t('contracts.name'), obs.contract ? obs.contract.name : ''],
                [this.$t('contracts.customer'), obs.contract ? obs.contract.customer.name : ''],
                [this.$t('observations.order'), obs.work_order && obs.work_order.task_type ? obs.work_order.task_type.name : ''],
                [this.$t('observations.status'), obs.closed_time ? this.$t('observations.closed') : this.$t('observations.open')],
                [this.$t('observations.location'), this.getStreetAddressString(obs) + (obs.city ? ' ' + obs.city.name : '')],
            ]
        },
        drawTrip: function() {
            if (this.trip && this.trip.geometry) {
                let color = '#565659'
                let dash = false
                const arrow = false
                let width = 3
                color
                this.map.drawPolyLine(0, this.TRACE, this.trip.geometry, color, dash, arrow, width, 1, false, false, null, false)
            }
        },
        drawObservations: function () {
            for(let observation of this.observations) {
                if (observation.type.geometry_type === geomTypes.POINT) {
                    this.map.showMapMarker(observation.id, this.OBSERVATION, observation.geometry.point.y,
                        observation.geometry.point.x, this.map.getMarkerStore().getObservationMarkerIcon(), false, 0)
                } else if (observation.type.geometry_type === geomTypes.LINESTRING) {
                    const color = observation.closed_time ? '#bcbcbc' : '#bb330e';
                    const points = observation.geometry.line_string.points.map(point => {
                        return {x: point[0], y: point[1]}
                    });
                    this.map.drawPolyLine(observation.id, this.OBSERVATION, points, color, false, false, 3, 1, true)
                }
            }
        },

    }
}
</script>

<style scoped>
.report-header {
    margin: 0;
    padding-top: .2em;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.summary-map-container {
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 1em;
    overflow: hidden;
    width: 750px;
    height: 350px;
    margin: auto;
    margin-bottom: .5em;
}

</style>

